<template>
  <div class="radio-select">
    <div class="radio-select-title">{{ title }}</div>
    <div class="radio-select-content">
      <div 
        v-for="item in options" 
        :key="item.label" 
        class="radio-select-option" 
        @click="optionSelect(item.value)"
      >
        <div class="circle" :class="{ selected: value === item.value }"></div>
        <span>{{ $t(item.label) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RadioSelect',
  props: ['title', 'value', 'options'],
  methods: {
    optionSelect(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.radio-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.radio-select-title {
  font-size: 16px;
  color: #333;
}

.radio-select-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  row-gap: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.radio-select-option {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.radio-select-option span {
  @include font_style(px2rem(16), px2rem(24));
}

.circle {
  position: relative;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2.5px solid #ffffff;
  margin-right: 8px;
  transition: opacity 0.3s;
}

.circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFC600;
  opacity: 0;
}

.circle.selected {
  border-color: #FFC600;
}

.circle.selected::before {
  opacity: 1;
  transform: translate(-50%, -50%);
}
</style>